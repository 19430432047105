@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;900&display=swap');

/* Global styling*/
body{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto',sans-serif;
    /* color: #eaecef; */
    /* background-color: var(--grey-color); */
}
h2{
  padding: 1rem;
}
a{
  color: unset !important;
}
a:hover{
  text-decoration: none;
  color: unset !important;
}
ul{
  padding-left: 0 !important;
}
ul li{
  list-style-type: none;
  margin: 10px 0;
}
ul li a{
  text-decoration: none;
  font-size: 14px;
  display: block;
  padding: 10px 15px;
  border-radius: 6px;
}
.main{
  background-color: var(--grey-color);
}

/* header style for small screen  */
.header-sm{
  padding: 0.8rem;
  background: var(--white-color);
  display: flex;
  }
/* header-logo */
.header-logo{
  margin: auto;
}
.header-logo > img {
  /* width: 100%;
  height: 30px; */
  height: 2rem;
}

header svg{
 font-size: 35px;
  color: var(--primary-color);
  transform: rotate(0deg);
  transition: all .5s ease;
}

header svg.inverted{
 font-size: 35px;
  color: var(--white-color);
  z-index: 14;
  transform: rotate(-180deg);
  transition: all .5s ease;
}



/* Side bar css */
.sidenav{
  width: 240px;
  background: var(--white-color);
  position: absolute;
  height: 100vh;
  left: -100%;
  transition: all .5s ease;
  z-index: 2;
  top: 0;
}
/* sidebar top image wrapper */
.sidenav .sidenav-top-img{
  height: 28vh;
  z-index: 13;
  padding: 0px !important;
  background: var(--primary-color) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

/* sidenav ul */
.sidenav > ul {
  z-index: 25;
  color: var( --secondary-color) !important;
  font-weight: bold;
}
/* sidenav item */
.sidenav-item, .sidenav-dropdown .sidenav-dropdown-item:last-child{  
  border-bottom: solid 1.8px #CCCCCC;
  /* font-weight: bold; */
}
.sidenav-item > a{
  padding-left: 1.5rem;
}
.sidenav-item .active svg{
  font-size: 1.8rem;
  color: var(--primary-color);
  float: right;
}

/* sidebar dropdown item*/
.sidenav-dropdown > li > a{
  padding: 0.5rem 1.8rem;
}
.sidenav.active{
  left: 0;
}
/* Side nav bottom image */
.sidenav-bottom-img{
  height: 25vh;
  margin: auto;
  padding: 2rem 4rem;
}
.sidenav-bottom-img >img{
  width: 100%;
}

/* Material UI tabs */
/* common class for all tab button  */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
  font-weight: 600;
  text-transform: capitalize;
  font-size: 1rem;
  color: var(--secondary-color);
}
/* class for selected tab button  */
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: var(--primary-color) !important;
}
/* class use for active tabs border  */
.css-1aquho2-MuiTabs-indicator{
  background-color: var(--primary-color);
}
.css-13xfq8m-MuiTabPanel-root{
  padding: 20px !important;
}


/* use to add style to card  */
.card-conatiner {
  /* box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px !important; */
  box-shadow: 0px 3px 15px #00000029 !important;
  margin-bottom: 1rem !important;
  /* height: 23rem; */
}
.card-head {
  color: var(--primary-color);
  padding: 1rem 1rem 0rem 1rem;
  }
  .scheduler-card{
    display: flex !important;
    justify-content: space-between !important;
  }
.card-head >h2{
  font-weight: bold;
  padding: 1rem 1rem 1rem 0rem;
}
.card-head > p {
  font-size: large;
  letter-spacing: 0.5px;
}
.card-head span {
  font-size: 6px;
}
.card-body {
  padding: 0rem 1rem 1rem 1rem !important;
  color: #676767;
}
.card-body h4 {
  color: #676767;
  font-weight: 700;
}
.card-body-head > svg {
  vertical-align: -5px;
  font-size: 1.625rem;
  font-weight: 100 !important;
  margin-right: 0.5rem;
}
.card-body-head > span {
  font-size: 1.5rem;
  font-weight: 400;
}
.card-body-text {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
}
.card-footer {
  border-top: none !important;
  background-color: var(--white-color) !important;
}
.card-footer-content {
  float: left;
  width: 15rem;
  text-align: center;
  font-size: 20px;
  margin-left: -20px !important;
}
.footer-duration {
  padding: 0.9rem !important;
  border-radius: 8px;
  font-size: large;
  text-align: center;
}
/*  Clases for text colors */
.primary-text {
  color: var(--primary-color) !important;
}
.text-yellow {
  color: var(--mustard-color) !important;
}
.text-orange {
  color: var(--red-color) !important;
}
/* classes  for background-color*/
.bg-yellow {
  background-color: var(--mustard-color);
  color: var(--white-color) !important;
}
.bg-light-grey{
  background-color: var(--light-grey);
}

/*  Responsive header */
@media only screen and (min-width: 1024px) {
  .header-sm{
    padding: 0.8rem;
    background: var(--white-color);
    display: flex;
    }
  .hidden {
    display: none;
  }
  .css-1aquho2-MuiTabs-indicator{
    height: 4.5px !important;
  }
  .css-heg063-MuiTabs-flexContainer{
    height: 10vh;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    font-size: 1.1rem;
    font-weight: 500;
    padding: 1rem 4rem;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    font-weight: bold;
  }
}
@media only screen and (max-width: 821px) {


.header-lg {
  display: none;
}

.header-sm{
  padding: 0.8rem;
  background: var(--white-color);
  display: flex;
 
  }
.header-logo{
  margin: auto;
}
.header-logo > img {

  height: 2rem;
}

header svg{
 font-size: 35px;
  color: var(--primary-color);
  transform: rotate(0deg);
  transition: all .5s ease;
}

header svg.inverted{
 font-size: 35px;
  color: var(--white-color);
  z-index: 14;
  transform: rotate(-180deg);
  transition: all .5s ease;
}
}
@media only screen and (min-width: 912px) and (min-device-width: 1024px) and (min-device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) {


  .header-lg {
    display: block;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    font-size: 1.1rem;
    font-weight: 500;
    padding: 1rem 2rem;
  }
  
  .header-sm{
    padding: 0.8rem;
    background: var(--white-color);
    display: flex;
   
    }
  /* .header-logo{
    margin: auto;
  }
  .header-logo > img {
  
    height: 2rem;
  }
  
  header svg{
   font-size: 35px;
    color: var(--primary-color);
    transform: rotate(0deg);
    transition: all .5s ease;
  }
  
  header svg.inverted{
   font-size: 35px;
    color: var(--white-color);
    z-index: 14;
    transform: rotate(-180deg);
    transition: all .5s ease;
  } */
  }

@media only screen and (max-width: 1024px) {
  .hidden-sm{
    display: none !important;
  }
  .card-body-head > span {
    font-size: 1.3rem;
  }
  .card-body-head > svg{
    font-size: 1.4rem;
  }
  .card-body-text{
    font-size: medium !important;
  }
  .card-body{
    padding-top: 2rem !important;
  }
  .css-1aquho2-MuiTabs-indicator{
    height: 3px !important;
  }
  .css-1ujykiq-MuiButtonBase-root-MuiTab-root{
    font-size: 1rem;
    color: var(--secondary-color);
    padding: 1rem 0;
  }
  .css-heg063-MuiTabs-flexContainer{
    justify-content: space-evenly;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    width: 50%;    
    max-width: unset !important;
  }
  .ScheduledEvent .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    width: 33.33%;
  }
}
@media only screen and (max-width: 426px) {
  .hidden-sm{
    display: none !important;
  }
  .card-footer-content{
    width: 11rem !important;
  }
  .footer-duration{
    padding: 0.7rem !important;
  font-size: medium !important;
  }
  .css-heg063-MuiTabs-flexContainer{
    justify-content: space-evenly;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    width: 50%;
  }
  
}


#scrollbox{
  overflow-y: auto;
  overflow-x: hidden;
}