.header-lg {
    /* width: 100%; */
    padding: 0.5rem 2rem;
    background-color: var(--primary-color);
    color: var(--white-color);
  }

  .header-lg-logo > img{
    width: 100%;
    height: 50px;
  }

  .btn-outline-light:hover {
    background-color: #734bd1 !important;
    color: var(--white-color) !important;
  }
  
   .btn-outline-light:focus{
      box-shadow: none !important;
   }
   .header-lg-btn  > svg {
    color: var(--white-color) !important;
    font-size: x-large;
   }

  