/* left section of calendar tab */
.col-left {
    border-radius: 0.5rem;
    /* padding-bottom: 15rem; */
    height: 72vh;
  }
 
  /* use to display Prefered Time duration   */
  .card-body__wrapper .row .col-6 > p {
    padding: 0.9rem !important;
    background-color: var(--light-grey);
    border-radius: 10px;
    font-size: 0.8rem;
  }

  @media only screen and (max-width: 769px) {
    /* .row{
      padding: 15px;
    } */
    .preferedtime-card{
      padding: 1rem !important;
      background-color: var(--light-grey);
    }
    .card-body__wrapper > .row{
      padding-left: 0.625rem;
      padding-right: 0.625rem;
    }
    .card-body__wrapper{
      height: 100vh;
      background-color: var(--light-grey);
      /* padding: 2rem 1rem 1rem 1rem !important; */
    }
    .card-body__wrapper > p{
      text-align: left;
    }
    .card-body__wrapper .row .col-6 > p{
      border-radius: 3px;margin-right: 10px;
    }
    .card-body__wrapper .row .col-6:first-child > p{
      margin-right: 10px;
    }
    .slot-time{
      padding: 0.5rem !important;
    /* background-color: #f8f7fc; */
    border-radius: 4px;
    font-size: 0.8rem;
    border: 1px solid var(--dark-grey); 
    cursor: pointer;
    }
  }
  @media only screen and (min-width: 769px) {
    .preferedtime-card{
      overflow: hidden;
       
     }
     .free-time{
       max-height:26rem;
       overflow-y: auto;
       overflow-x: hidden;
     }
    .col-left .card-body{
      padding-right: 0 !important;
    }
  }

  .slot-time{
    cursor: pointer;
  }