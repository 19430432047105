/* Calendar js classes */
.calendar{
  color: var(--secondary-color) ;
  position: "relative";
  opacity: 1;
  z-index: 0;
  /* padding: 0 1rem; */
}

.fc-theme-standard .fc-popover {
  border: 1px solid #ddd;
  border: 1px solid var(--fc-border-color, #ddd);
  background: #fff;
  height: 11rem;
  overflow-y: scroll;
  background: var(--fc-page-bg-color, #fff);
}

  /* calendar js common style for mobile and desktop screen */
  .fc .fc-toolbar-title {
    text-transform: uppercase;
    /* font-size: 1.5rem !important; */
  }
  .fc .fc-button-primary{
    background-color: var(--light-grey-300) !important;
    border: none !important;
    text-transform: capitalize !important;
    opacity: 1 !important;
    color: var(--secondary-color) !important;
    margin-right: 10px !important;
    font-weight: 400 !important;
  }

  .fc .fc-button-group .fc-button-active, .fc .fc-button-active {
    background-color: var(--orange-color) !important;
    color: var(--white-color) !important;
  }

  .fc-button-group .fc-button-primary:focus, .fc .fc-button-primary:focus  {
    box-shadow: none !important;
  }
  .fc-col-header{
    background-color: var(--light-grey-200);    
  }
@media only screen and (max-width: 1024px) {
.container{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
  .preferedtime .col-lg-4{
    display: none;
  }

/* .calendar {
    color: var(--secondary-color) !important;
} */
/* .card-body-duration > div, .slot-time {
  padding: 0.9rem !important;
  background-color: #f8f7fc;
  border-radius: 10px;
  font-size: medium;
} */
.fc .fc-toolbar{
  align-items: normal;
}
.fc .fc-view-harness{
  height: 350px !important;
}
.fc .fc-button-primary{
    /* background-color: var(--white-color) !important;
    border: none !important;
    text-transform: capitalize !important;
    opacity: 1 !important;
    color: var(--secondary-color) !important; */
    /* margin-right: 5px !important; */
    /* font-weight: 400 !important; */
}
.fc .fc-prev-button,.fc .fc-next-button {
    color: var(--primary-color) !important;
    padding: 4px;
}
.fc .fc-prev-button >span, .fc .fc-next-button >span {
    font-weight: 600 !important;
}
.fc-toolbar-chunk{
    position: relative;
    padding: 0;
}
.fc .fc-button .fc-icon {
  font-size: 1.7em;
}
.fc .fc-toolbar-title{
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
}
.fc .fc-button-active{
    /* background-color: var(--orange-color) !important;
    color: var(--white-color) !important; */
    margin-right: 5px;
}
/* .fc .fc-button-primary:focus {
    box-shadow: none !important;
  } */
 .fc .fc-button-primary:last-child {
    margin-right: 0 !important;
  }
.fc .fc-timeGridWeek-button, .fc .fc-dayGridMonth-button, .fc .fc-timeGridDay-button {
    /* margin-top: 1rem; */
    font-size: 0.85rem;
    /* padding: 0.2rem 0.8rem; */
    border-radius: 15px;
    /* margin-left: 15px; */
}
/* .fc .fc-timeGridWeek-button::before{
    padding: 0.2rem;
    border: solid 2px black;
    border-radius: 30px;
}
thead{
  background-color: var(--light-grey-200);
} */
}


  /*  */
  @media only screen and (min-width: 1024px) {
.calendar-content {
  /* width: 1250px; */
  /* background-color: var(--white-color); */
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px,
    rgb(0 0 0 / 6%) 0px 0px 0px 1px;
  /* height: 72vh; */
}

.preferedtime .card {
  border: none !important;
}


/* right section of calendar tab */
.col-right {
  border-radius: 20px !important;
  /* box-shadow: rgb(0 0 0 / 4%) -7px 0px 10px; */
  border: none;
  border-left: 1px solid var(--white-color-100);
  margin-left: -40px; 
  height: 72vh;
  overflow: hidden;
}

/* calendar js wrapper div */
.calendar {
  padding: 1rem;
}


/* Calendar js classes */
/* .fc-button-group .fc-button-primary {
  background-color: #f1f1f1 !important;
  border: none !important;
  text-transform: capitalize !important;
  opacity: 1 !important;
  color: var(--secondary-color) !important;
  margin-right: 10px !important;
  font-weight: 400 !important;
} */
#fc-dom-2{
  padding: 0.5rem;
  font-size: 1.4rem !important;
}

/* .fc-button-group .fc-button-primary:focus {
  box-shadow: none !important;
} */
.fc-button-group > button:last-child {
  margin-right: 0 !important;
}
.fc-button-group .fc-prev-button {
  margin-right: 0.5rem !important;
}
.fc-button-group .fc-button-primary > span {
  color: var(--primary-color) !important;
  font-weight: 500;
  font-size: 1.5em;
  font-weight: 900;
}
.fc .fc-today-button {
  width: 7rem;
  background-color: var(--orange-color) !important;
  color: var(--white-color) !important;
  text-transform: capitalize !important;
  opacity: 1 !important;
}
.fc-button-group .fc-button-active {
  /* background-color: var(--orange-color) !important;
  color: var(--white-color) !important; */
  margin-right: 10px;
}
/* .fc-col-header {
  background-color: #edeeed !important;
} */
.fc-col-header .fc-col-header-cell {
  padding: 1rem;
}
.fc .fc-dayGridMonth-view .fc-scroller-harness-liquid {
  height: 49vh !important;
}
.fc .fc-timeGridWeek-view .fc-scroller-harness-liquid{
  height: 39vh !important;
}
.fc .fc-timeGridDay-view .fc-scroller-harness-liquid {
  height: 42vh !important;
}
.fc .fc-view-harness-active > .fc-view{
  position: relative !important;
}
}
.fc .fc-timegrid-axis-frame {
  text-transform: capitalize;
  white-space: nowrap;
  font-size: 15px;
}