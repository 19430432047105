.input-text {
    position: relative;
  }
  .input-text > label {
    position: absolute;
    left: 2rem;
    top: -12px;
    color: #9e9e9e;
    font-size: 14px;
    color: var(--primary-color);
    background-color: var(--white-color);
    padding: 0 5px;
    font-weight: 700;
  }
  input, textarea, select {
    width: 100%;
    padding: 1rem 2rem;
    border-radius: 2rem;
    border: 2px solid #d9d0f0;
    background-color: var(--white-color);
  }
  textarea{
    resize: none !important;
  }
  input:focus, textarea:focus, select:focus {
    outline: none;
    border: 2px solid #d9d0f0 !important;
  }
  
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  /* Input choose option */
  .select-selected:after {
    position: absolute;
    content: "  ";
    top: 1.8rem;
    right: 1.5rem;
    /* width: 10px !important;
    height: 0px; */
    height: 1rem;
    border: 10px solid transparent;
    border-color: var(--primary-color) transparent transparent transparent;
  }
  
  /* Modal footer */
  .form-footer-container {
    border: none !important;
    justify-content: center !important;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
  }
  /* Modal footer  btn*/
  .footer-btn {
    padding: 1rem 5rem;
    border: none;
    letter-spacing: 0.5px;
    font-size: large;
    border-radius: 32px;
    background-color: var(--primary-color);
    color: var(--white-color);
    font-weight: 500;
  }

  .availability{
    width: 100%;
    padding: 1rem 2rem;
    border-radius: 2rem;
    border: 2px solid #d9d0f0;
    background-color: var(--white-color);
  }
  .availability-input{
    margin:1rem;
  }
  .radioEndTime{
   display: flex !important;
    justify-content: space-evenly  !important;
    margin-top: 5px;
  }
  .radioEndTime input{
    /* width: fit-content; */
    padding: 0px;
  }

  .datePickerNew select{
    padding: 0px 0.5rem;
    
  }
  .react-datepicker__header{
    background-color: #ffffff !important;
  }

  .react-datepicker__day--selected, .react-datepicker__time-list-item--selected{
    background-color: var(--primary-color) !important;
  }

  .flex{
    display: flex !important;
  }
  .react-datepicker__input-container{
    width: unset;
  }
  .rc-time-picker-input{
    height: unset !important;
    font-size: unset !important;
    color: black !important;
  }
  .react-datepicker__input-container input, .rc-time-picker-input{
    width: 100% !important;
    padding: 0.5rem 1rem !important;
    border-radius: 2rem !important;
    border: 2px solid #d9d0f0 !important;
    background-color: var(--white-color) !important;
   
  } 