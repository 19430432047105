.availability {
  padding: 0 !important;
}



@media only screen and (max-width: 769px) {
  .calendar-availability{
    /* z-index: 0 !important; */
    padding: 1rem 1.5rem;
    box-shadow: -1px 9px 15px #0000001a;;
}
    .fc .fc-toolbar-title{
        font-size: 1rem;
        text-transform: uppercase;
        font-weight: 700;
        /* position: absolute; */
        /* top: -15px; */
        /* padding: 0.8rem 2rem 0rem 2rem !important;
        margin-top: 0 !important; */
    }
    
}
/* .container{
    width: 300px;
    border: 1px solid black;
    margin: 0 auto;
    box-shadow: 10px 10px 0px black;
} */

.Month{
    font-size: 26px;
    font-weight: bold;
    text-align: center;
}
.days{
    overflow-x: scroll;
}
/* tr{
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}
ul{
    padding: 15px;
    display: inline-flex;
    justify-content: space-between;
    align-content: center;
    gap: 1.6rem;
} */

/* Calendar */

.calendar {
    display: block;
    position: relative;
    /* width: 100%; */
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1rem;
    /* padding: 1.5em 0; */
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .header .icon {
    cursor: pointer;
    transition: 0.15s ease-out;
  }
  
  .calendar .header .icon:hover {
    /*transform: scale(1.75);*/
    transition: 0.25s ease-out;
    color: var(--main-color);
  }
  
  .calendar .header .icon:first-of-type {
    margin-left: 1em;
  }
  
  .calendar .header .icon:last-of-type {
    margin-right: 1em;
  }
  
  .calendar .days {      
    /* margin-left: 0.8rem; */
    /* text-align: center; */
    text-transform: uppercase;
    font-weight: 400;
    color: var(--text-color-light);
    font-size: 70%;
    padding: 0.75em 0;
    border-bottom: 1px solid var(--border-color);
  }
  
  .calendar .body .cell {
    position: relative;
    height: 3rem;
    gap: -3px;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    /* background: var(--neutral-color); */
    transition: 0.25s ease-out;
    font-size: 1.5em;
  }
  
  .calendar .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
  }
  
  .calendar .body .selected {
    /* border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1; */
    height: 3rem;
    background-color: var(--primary-color) !important;
    border-radius: 1rem;
    display: inline-block;
    color:var(--white-color);
    
  }
  /* .calendar .body .today {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
    border-image-slice: 1;
  } */
  .calendar .body .row {
    border-bottom: 1px solid var(--border-color);
  }
  
  /* .calendar .body .row:last-child {
    border-bottom: none;
  } */
  
  .calendar .body .cell:last-child {
    border-right: none;
  }
  
  .calendar .body .cell .number {
    /* position: absolute; */
    font-size: medium;
    line-height: 1;
    top: 0.75em;
    /* right: 0.85em; */
    /* left: 0.9rem; */
    /* padding: 0.75em 0; */
    /* display: grid; */
    /* justify-content: center; */
    font-weight: 400;
    /* margin-left: 12px; */
    /* width: 14px; */
    /* margin-right: 3.85rem;*/
  }
  
  .calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
  }
  
  .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
  }
  
  .calendar .body .cell:hover .bg,
  .calendar .body .selected .bg {
    opacity: 0.05;
    transition: 0.5s ease-in;
  }
  .calendar .body .cell.today .bg {
    color: #ff1a79;
    opacity: 0.05;
  }
  
  .calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
  }
  .icon-left {
      color:var(--primary-color);
      font-size: 1.3rem;
      display: flex;
      justify-content: left;
      /* width: 80%; */
  }
  .icon-right {
    color:var(--primary-color);
    font-size: 1.3rem;
    display: flex;
    justify-content: right;
    /* width: 80%; */
}
  .col-start .col-end{
      display: flex;
      justify-content: left;
  }
  @media only screen and (max-width: 769px) {
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    /* padding-right: 0 !important; 
     padding-left: 0 !important;  */
    margin-top: var(--bs-gutter-y);
  }
}
  .row{
      align-items: center;
  }
  .monthyear{
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 1rem rem;
    margin-right: 3rem;
    /*
    text-align: center; */
  }