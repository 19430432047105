/* use to add border top for Upcoming Meeting (purple)*/
.card-border-meeting {
  border-top: 8px solid var(--primary-color) !important;
}
.notification{
  padding:1rem;
}
.notif-title{
  color:var(--secondary-color);
  font-weight: 700;
  padding-left: 1rem;
  padding-top: 1rem;
  font-size: 1.8rem;
}
.scheduled-title{
  color:var(--secondary-color);
  font-weight: 700;
  padding-top: 1rem;
  font-size: 1.8rem;
}
/* .footer-duration {
  padding: 0.9rem !important;
  border-radius: 8px;
  font-size: large;
  text-align: center;
} */
#scrollbox .row>* {
  display: flex;
}
#scrollbox .row {
  align-items: stretch;
}

.col-lg-4 > .card {
  min-width: 100%;
}