
/* Style for dropdown */
.dropdown > a {
    margin: auto;
}
.dropdown > a > svg{
    font-size: 30px;
}
.dropdown-menu{
    color: var(--primary-color) !important;
}
.dropdown-menu > .dropdown-item.active{
    background-color: var(--light-grey) !important;
}
.dropdown-item > svg{
    margin-right: 5px;
}
.dropdown-menu{
    /* inset: 0px 10px auto auto; */
    transform: translate3d(-10px, 56px, 0px) !important;
}