/* Rejected Meeting */

/* use to add border top for Rejected Meeting*/
.card-border-rejected-meeting {
    border-top: 8px solid var(--red-color) !important;
  }
  .text-rejected {
    /* font-size: 22px; */
    font-size: x-large;
    margin-left: -20px !important;
  }
.card-footer > p {
    padding: 0.9rem 0.9rem 0.9rem 0;
    margin-bottom: 0.5rem;
}
@media only screen and (max-width: 769px) {
  .text-rejected {
    font-size: larger;
  }
}